import {defineStore} from 'pinia'
import ApiService from "@/services/ApiService"

export const useHourlyRateVStore = defineStore('tabs', {
    state: () => {
        return {
            // data
            reports: [],
            loading: true,
            // filters
            bd: null,
            pm: null,
            pmOptions: [],
            bdOptions: [],
            includeArchivedProjects: 0,
            includeSLA: 0,
            fromHourlyRateA: 0,
            tillHourlyRateA: 100000,
            fromHourlyRateP: 0,
            tillHourlyRateP: 100000,
            fromWrittenHours: 0,
            tillWrittenHours: 100000,
            fromPlannedHours: 0,
            tillPlannedHours: 100000,
        }
    },
    getters: {},
    actions: {
        loadData() {
            this.loading = true
            return ApiService.get(`data/hourlyRatesAP`, {
                'includeArchived': Boolean(this.includeArchivedProjects),
                'pm': this.pm,
                'bd': this.bd,
                'years': this.yearSelection,
                'projectManager': this.projectManager
            }).then((response) => {
                console.log(response.data);
                this.reports = response.data.reports
                this.pm = response.data.filters.pm
                this.pmOptions = Object.values(response.data.filters.pmOptions)
                this.bd = response.data.filters.bd
                this.bdOptions = Object.values(response.data.filters.bdOptions)
                this.includeArchivedProjects = response.data.filters.includeArchivedProjects
                this.loading = false
            }).catch(e => {
                console.log(e)
                this.loading = false
            })
        },
        updateIncludeArchivedProjects(value) {
            this.includeArchivedProjects = value
            this.loadData()
        },
        updatePm(value) {
            this.pm = value
            this.loadData()
        },
        updateBd(value) {
            this.bd = value
            this.loadData()
        },
    }
})
