<template>
  <div class="bg-zinc-50">
    <MainNav/>
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="pt-6 lg:grid">
        <div class="lg:col-span-2 lg:mt-0 xl:col-span-2 bg-white shadow">
          <div class="p-5">
            <div class="border-b border-gray-200 pb-1 pt-4">
              <h1 class="text-4xl mb-5 font-bold tracking-tight text-gray-900">Overzicht factureerbare uren</h1>
              <p class="text-gray-900 text-sm mb-3">Welke uren nemen we mee in dit overzicht? Elk uur dat geschreven is
                op een
                declarabele projectpost (met een verkoopprijs groter dan 0)
                die '<u>Begroot</u>' of '<u>Nacalculatie</u>' staat en <u>nog niet is gefactureerd</u> aan de klant.
                Serviceovereenkomsten en offertes uitgesloten van dit overzicht.</p>
            </div>


            <div class="grid-cols-8">
              <div class="mt-10 mb-10 flex justify-center" v-if="billableHoursStore.loading">
                <img :src="loadingImage">
              </div>
              <div v-if="!billableHoursStore.loading">
                <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4 mb-5">
                  <div class="overflow-hidden rounded-lg bg-white px-2 py-3 shadow sm:p-3">
                    <dt class="truncate text-sm font-medium text-gray-500">Uren</dt>
                    <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                      {{ billableHoursStore.reports.hoursCount }} uur
                    </dd>
                  </div>
                  <div class="overflow-hidden rounded-lg bg-white px-2 py-3 shadow sm:p-3">
                    <dt class="truncate text-sm font-medium text-gray-500">Omzet</dt>
                    <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">&euro; {{
                        billableHoursStore.reports.openRevenue
                      }}
                    </dd>
                  </div>
                  <div class="overflow-hidden rounded-lg bg-white px-2 py-3 shadow sm:p-3">
                    <fieldset>
                      <dt class="truncate text-sm font-medium text-gray-500">Na hoeveel dagen factureren</dt>
                      <dd class="mt-4 font-semibold tracking-tight text-gray-900">
                        <div class="flex items-center">
                          <span class="pr-4">{{ ageDays }}</span>
                          <input id="steps-range" v-model="ageDays" :disabled="billableHoursStore.loading" type="range"
                                 min="0"
                                 max="90" step="5"
                                 class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700">
                        </div>
                      </dd>
                    </fieldset>
                    <!--                    <fieldset class="mt-5">-->
                    <!--                      <legend class="block text-sm font-medium text-gray-900 mb-2">Jaren</legend>-->
                    <!--                      <InputMultipleCheckbox @update:value="updateYears" v-model:value="yearSelection"-->
                    <!--                                             :disabled="filterStore.loading"-->
                    <!--                                             :options="filterStore.yearOptions"/>-->
                    <!--                    </fieldset>-->
                  </div>
                  <div class="overflow-hidden rounded-lg bg-white px-2 py-3 shadow sm:p-3">
                    <fieldset>
                      <dt class="truncate text-sm font-medium text-gray-500">Inclusief gearchiveerde opdrachten</dt>
                      <Switch
                          v-model="archived"
                          :class="[archived && !billableHoursStore.loading ? 'bg-blue-600' : 'bg-gray-200', 'mt-4']"
                          :disabled="billableHoursStore.loading"
                          class="relative inline-flex h-6 w-11 items-center rounded-full"
                      >
                        <span class="sr-only">Enable notifications</span>
                        <span
                            :class="archived ? 'translate-x-6' : 'translate-x-1'"
                            class="inline-block h-4 w-4 transform rounded-full bg-white transition"
                        />
                      </Switch>
                    </fieldset>
                  </div>

                </dl>
                <div class="col-start-1 col-end-3">
                  <div class="flex rounded-md shadow-sm mb-2 mt-2">
                    <div class="relative w-full">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor"
                             viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clip-rule="evenodd"></path>
                        </svg>
                      </div>
                      <input type="text" v-model="searchValue" id="simple-search"
                             class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                             placeholder="Zoeken" required>
                    </div>
                  </div>
                </div>

                <EasyDataTable
                    :headers="headers"
                    :items="billableHoursStore.reports.tableData"
                    :search-field="searchField"
                    :search-value="searchValue"
                    :rows-per-page="20"
                    :alternating="true"
                    sort-by="hourly_rate"
                    sort-type="asc"
                    buttons-pagination
                    :loading="billableHoursStore.loading"
                    class="shadow"
                >
                  <template #header-gripp_id>
                    <div class="customize-header">
                      <img class="header-icon" :src="grippLogo">
                    </div>
                  </template>
                  <template #item-gripp_id="item">
                    <a :href="`https://acato.gripp.com/public/index.php?u=96054#`+jsonGrippLink(item.uid_gripp)"
                       class="text-blue-700" target="_blank">#{{ item.gripp_id }}</a>
                  </template>
                  <template #item-hourly_rate="item">
                    &euro; {{ item.hourly_rate }}
                  </template>
                  <template #item-hourly_rate_2="item">
                    &euro; {{ item.hourly_rate_2 }}
                  </template>
                  <template #item-attention="item">
                    <ExclamationTriangleIcon class="h-5 w-5 text-red-500" v-if="item.hourly_rate < 80"/>
                  </template>
                  <template #item-operation="item">
                    <EnvelopeOpenIcon class="h-5 w-5 cursor-pointer" title="Voeg bericht toe aan Timeline in Gripp"
                                      @click="projectModalTimelineStore.openModal(item.uid_gripp)"/>
                  </template>
                </EasyDataTable>
                <GrippTimeline/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MainFooter/>
    <ProjectModal/>
  </div>
</template>

<script setup>
import {watch, onMounted, ref} from 'vue'
import ProjectModal from "@/components/Modals/ProjectModal";
import {useBillableHoursStore} from "@/stores/billable-hours-module";
import MainFooter from "@/components/MainFooter";
import MainNav from "@/components/MainNav";
import {Switch} from "@headlessui/vue";
import _ from "lodash";
import grippLogo from "@/assets/gripp.webp";
import {useProjectModalTimelineStore} from "@/stores/gripp-timeline-module";
import {ExclamationTriangleIcon, EnvelopeOpenIcon} from '@heroicons/vue/24/outline'
import GrippTimeline from "@/components/Modals/GrippTimeline";

const billableHoursStore = useBillableHoursStore()
const loadingImage = require('@/assets/loading-animation.svg');
const ageDays = ref(billableHoursStore.billableHoursAgeInDays);
const archived = ref(billableHoursStore.includeArchivedProjects)
const searchField = ref(['company', 'project', 'hours', 'manager']);
const searchValue = ref('');
const projectModalTimelineStore = useProjectModalTimelineStore()

const headers = [
  {text: "", value: "attention"},
  {text: "ID", value: "gripp_id"},
  {text: "Klant", value: "company", sortable: true},
  {text: "Opdracht", value: "project", sortable: true},
  {text: "Projectleider", value: "manager", sortable: true},
  {text: "Uren", value: "hours", sortable: true},
  {text: "Uurtarief A", value: "hourly_rate", sortable: true},
  {text: "Uurtarief P", value: "hourly_rate_2", sortable: true},
  {text: "", value: "operation"},
];

const jsonGrippLink = function (id) {
  return btoa('{"action":"viewSubpage","actionparams":{"sn_":{"n9098n":{"recordid":"' + id + '","o_":"view"}},"re_":"n9102n"}}');
}

onMounted(() => {
  billableHoursStore.loadData()
})

watch(() => ageDays.value, _.debounce(function () {
  billableHoursStore.updateBillableHoursAgeInDays(ageDays.value)
}, 1000));

watch(() => archived.value, function () {
  billableHoursStore.updateIncludeArchivedProjects(archived.value)
});

</script>

<style scoped>
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes floating {
  0% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0 rgba(0, 0, 0, 0.2);
    transform: translatey(-10px);
  }
  100% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.customize-header {
  display: flex;
  justify-items: center;
  align-items: center;
}

.header-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
}

table tr td {
  font-size: 14px;
}

.vue3-easy-data-table__footer {
  padding: 25px 10px !important;
}

</style>
