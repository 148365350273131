<template>
  <div class="bg-zinc-50">
    <MainNav/>
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="pt-6 lg:grid">
        <div class="lg:col-span-2 lg:mt-0 xl:col-span-2">
          <div class="mt-5 pb-20 min-h-full">
            <div class="grid grid-cols-12 gap-3 wrapper">
              <div class="col-span-12 bg-white shadow">
                <div class="mt-10 mb-10 flex justify-center" v-if="loading">
                  <img :src="loadingImage">
                </div>
                <div class="p-3" v-if="!loading">
                  <div class="sm:flex sm:items-center m-4">
                    <div class="sm:flex-auto">
                      <h1 class="text-base font-semibold leading-6 text-gray-900">Ingeplande uren per projectleider</h1>
                      <p class="mt-2 text-sm text-gray-700">Het aantal declarabel ingeplande uren op opdrachten per PM-er, per week.</p>
                    </div>
                  </div>
                  <table class="w-full divide-y divide-gray-300 mb-7">
                    <thead class="divide-x divide-gray-20">
                    <tr class="divide-x divide-gray-200">
                      <th class="px-4 py-3.5 text-left text-sm font-semibold text-gray-90" scope="col">Projectleider</th>
                      <th class="px-4 py-3.5 text-left text-sm font-semibold text-gray-90" scope="col"
                          v-for="(key) in weekstartData.planning.columns" :key="key">
                        {{ key }}
                      </th>
                    </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white" v-for="(projectManagerData, key) in weekstartData.planning.data"
                           :key="projectManagerData.id">
                    <tr class="divide-x divide-gray-200">
                      <td class="whitespace-nowrap px-4 py-2 font-medium">
                        {{ key }}
                      </td>
                      <td class="whitespace-nowrap px-4 py-2" v-for="(key) in weekstartData.planning.columns"
                          :key="key">
                        <RingSpan :fraction="projectManagerData['billable'][key]" :show-raw-value="true"/>
                      </td>
                    </tr>
<!--                    <tr class="divide-x divide-gray-200" v-if="inclND">-->
<!--                      <td class="whitespace-nowrap py-2 px-4 text-xs font-mono">Niet declarabel</td>-->
<!--                      <td class="whitespace-nowrap py-2 px-4 text-xs"-->
<!--                          v-for="(key) in weekstartData.planning.columns" :key="key">-->
<!--                        <RingSpan :fraction="(team.nonBillableHours[key] ?? 0) / team.capacity[key]" :colored="false" v-if="!inHours"/>-->
<!--                        <RingSpan :fraction="team.nonBillableHours[key] ?? 0" :colored="false" :isPercentage="false" v-if="inHours"/>-->
<!--                      </td>-->
<!--                    </tr>-->
                    </tbody>
<!--                    <tfoot>-->
<!--                    <tr class="divide-x divide-gray-200">-->
<!--                      <td class="whitespace-nowrap px-4 py-2 font-medium">-->
<!--                        Totaal-->
<!--                      </td>-->
<!--                      <td class="whitespace-nowrap px-4 py-2" v-for="(key) in weekstartData.planning.columns" :key="key">-->
<!--                        <RingSpan :fraction="weekstartData.planning.all.billableHours[key]/weekstartData.planning.all.capacity[key]" v-if="!inHours"/>-->
<!--                        <RingSpan :fraction="weekstartData.planning.all.billableHours[key]" :isPercentage="false" :colored="false" v-if="inHours"/>-->
<!--                      </td>-->
<!--                    </tr>-->
<!--                    </tfoot>-->
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MainFooter/>
    <ProjectModal/>
  </div>
</template>

<script setup>
import {onActivated, onMounted, ref} from 'vue'
import {useRouter} from "vue-router";
import ProjectModal from "@/components/Modals/ProjectModal";
import {useAuthStore} from "@/stores/auth-module";
import MainFooter from "@/components/MainFooter";
import MainNav from "@/components/MainNav";
import ApiService from "@/services/ApiService"
import RingSpan from "@/components/RingSpan.vue";
// import RingSpan from "@/components/RingSpan.vue";

const loadingImage = require('@/assets/loading-animation.svg');

const router = useRouter();
const weekstartData = ref();
const loading = ref(true);
// const inclND = ref(false);
// const inHours = ref(false);
// const resultsInHours = ref(false);

onActivated(() => {
  const authStore = useAuthStore()
  if (!authStore.loggedIn) {
    router.push('/');
  }
})

onMounted(() => {
  ApiService.get('data/pm-planning').then((response) => {
    weekstartData.value = response.data
    loading.value = false;
  })
})

</script>

<style scoped>
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

.load-wrapper {
  transform: translatey(0px);
  -webkit-animation: floating 1.5s linear infinite;
  animation: floating 4s ease-in-out infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes floating {
  0% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0 rgba(0, 0, 0, 0.2);
    transform: translatey(-10px);
  }
  100% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}
</style>
