import {createRouter, createWebHistory} from 'vue-router'
import LoginPage from "@/views/Auth/LoginPage";
import GooglePage from "@/views/Auth/GooglePage";
import DataDashboard from "@/views/DataDashboard";
import WeekStart from "@/views/WeekStart"
import ProjectCheck from "@/views/ProjectCheck"
import BillableHours from "@/views/BillableHours.vue";
import ServerCheck from "@/views/ServerCheck.vue";
import PmPlanning from "@/views/PmPlanning.vue";
import HourlyRateV from "@/views/HourlyRateV.vue";

const routes = [
    {
        path: '/',
        name: 'Login',
        component: LoginPage
    },
    {
        path: '/server-check',
        name: 'Server check',
        component: ServerCheck
    },
    {
        path: '/auth/google/callback',
        name: 'Google authentication',
        component: GooglePage
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: DataDashboard
    },
    {
        path: '/billable-hours',
        name: 'Facturatiecheck',
        component: BillableHours
    },
    {
        path: '/hourly-rate-v',
        name: 'Uurtarief voor V',
        component: HourlyRateV
    },
    {
        path: '/weekstart',
        name: 'Weekstart',
        component: WeekStart
    },
    {
        path: '/projectcheck',
        name: 'Projectcheck',
        component: ProjectCheck
    },
    {
        path: '/pm-planning',
        name: 'Projectleiders planning',
        component: PmPlanning
    }]

    const router = createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes
    })

export default router
