<template>
  <div class="bg-zinc-50">
    <MainNav/>
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="pt-6 lg:grid">
        <div class="lg:col-span-2 lg:mt-0 xl:col-span-2">
          <div class="mt-5 pb-20 min-h-full">
            <div class="grid grid-cols-12 gap-3 wrapper">
              <div class="col-span-12 bg-white shadow">
                <div class="mt-10 mb-10 flex justify-center" v-if="loading">
                  <img :src="loadingImage">
                </div>
                <div class="p-3" v-if="!loading">
                  <div class="sm:flex sm:items-center m-4">
                    <div class="sm:flex-auto">
                      <h1 class="text-base font-semibold leading-6 text-gray-900">Planning komende 6 weken</h1>
                      <p class="mt-2 text-sm text-gray-700">We slaan in dit overzicht de planning plat om hoogover een beeld te krijgen van hoe de zaken ervoor staan.</p>
                      <div class="mt-2 text-sm text-gray-700 flex justify-start items-center">
                        <span class="mr-2"><Switch
                            v-model="inclND"
                            :class="inclND ? 'bg-teal-900' : 'bg-slate-200'"
                            class="relative inline-flex h-[16px] w-[37px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                        >
                          <span class="sr-only">Use setting</span>
                          <span
                              aria-hidden="true"
                              :class="inclND ? 'translate-x-4' : 'translate-x-0'"
                              class="pointer-events-none inline-block h-[12px] w-[12px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
                          />
                        </Switch></span>
                        <span class="py-1">Inclusief ND planning & vrije capaciteit</span>
                      </div>
                      <div class="mt-2 text-sm text-gray-700 flex justify-start items-center">
                        <span class="mr-2"><Switch
                            v-model="inHours"
                            :class="inHours ? 'bg-teal-900' : 'bg-slate-200'"
                            class="relative inline-flex h-[16px] w-[37px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                        >
                          <span class="sr-only">Use setting</span>
                          <span
                              aria-hidden="true"
                              :class="inHours ? 'translate-x-4' : 'translate-x-0'"
                              class="pointer-events-none inline-block h-[12px] w-[12px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
                          />
                        </Switch></span>
                        <span class="py-1">Toon uren (ipv %)</span>
                      </div>
                    </div>
                  </div>
                  <table class="w-full divide-y divide-gray-300 mb-7">
                    <thead class="divide-x divide-gray-20">
                    <tr class="divide-x divide-gray-200">
                      <th class="px-4 py-3.5 text-left text-sm font-semibold text-gray-90" scope="col">Team</th>
                      <th class="px-4 py-3.5 text-left text-sm font-semibold text-gray-90" scope="col"
                          v-for="(key) in weekstartData.planning.columns" :key="key">
                        {{ key }}
                      </th>
                    </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white" v-for="(team) in weekstartData.planning.data"
                           :key="team.id">
                    <tr class="divide-x divide-gray-200">
                      <td class="whitespace-nowrap px-4 py-2 font-medium">
                        {{ team.name }}
                      </td>
                      <td class="whitespace-nowrap px-4 py-2" v-for="(key) in weekstartData.planning.columns"
                          :key="key">
                        <RingSpan :fraction="team.billableHours[key]/team.capacity[key]" v-if="!inHours"/>
                        <RingSpan :fraction="team.billableHours[key]" :isPercentage="false" :colored="false" v-if="inHours"/>
                      </td>
                    </tr>
                    <tr class="divide-x divide-gray-200" v-if="inclND">
                      <td class="whitespace-nowrap py-2 px-4 text-xs font-mono">Niet declarabel</td>
                      <td class="whitespace-nowrap py-2 px-4 text-xs"
                          v-for="(key) in weekstartData.planning.columns" :key="key">
                        <RingSpan :fraction="(team.nonBillableHours[key] ?? 0) / team.capacity[key]" :colored="false" v-if="!inHours"/>
                        <RingSpan :fraction="team.nonBillableHours[key] ?? 0" :colored="false" :isPercentage="false" v-if="inHours"/>
                      </td>
                    </tr>
                    <tr class="divide-x divide-gray-200" v-if="inclND">
                      <td class="whitespace-nowrap py-2 px-4 text-xs font-mono">Vrije capaciteit <span class="text-xs" v-if="team.shockAbsorberHoursTotal > 0">(+ shock absorber)</span>
                      </td>
                      <td class="whitespace-nowrap py-2 px-4 text-xs" v-for="(key) in weekstartData.planning.columns" :key="key">
                        <RingSpan :fraction="(team.capacity[key] - (team.billableHours[key] + (team.nonBillableHours[key] ?? 0))) / team.capacity[key]" :colored="false" v-if="!inHours"/>
                        <RingSpan :fraction="(team.capacity[key] - (team.billableHours[key] + (team.nonBillableHours[key] ?? 0)))" :colored="false" :isPercentage="false" v-if="inHours"/>
                      </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="divide-x divide-gray-200">
                      <td class="whitespace-nowrap px-4 py-2 font-medium">
                        Totaal
                      </td>
                      <td class="whitespace-nowrap px-4 py-2" v-for="(key) in weekstartData.planning.columns" :key="key">
                        <RingSpan :fraction="weekstartData.planning.all.billableHours[key]/weekstartData.planning.all.capacity[key]" v-if="!inHours"/>
                        <RingSpan :fraction="weekstartData.planning.all.billableHours[key]" :isPercentage="false" :colored="false" v-if="inHours"/>
                      </td>
                    </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div class="col-span-12 bg-white shadow">
                <div class="p-3" v-if="!loading">
                  <div class="sm:flex sm:items-center m-4">
                    <div class="sm:flex-auto">
                      <h1 class="text-base font-semibold leading-6 text-gray-900">Resultaat</h1>
                      <p class="mt-2 text-sm text-gray-700">Hier per team, per maand & year to date, een overzicht van de gerealiseerde declarabiliteit.</p>
                      <div class="mt-2 text-sm text-gray-700 flex justify-start items-center">
                        <span class="mr-2"><Switch
                            v-model="resultsInHours"
                            :class="resultsInHours ? 'bg-teal-900' : 'bg-slate-200'"
                            class="relative inline-flex h-[16px] w-[37px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                        >
                          <span class="sr-only">Use setting</span>
                          <span
                              aria-hidden="true"
                              :class="resultsInHours ? 'translate-x-4' : 'translate-x-0'"
                              class="pointer-events-none inline-block h-[12px] w-[12px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
                          />
                        </Switch></span>
                        <span class="py-1">Toon declarabele uren (ipv %)</span>
                      </div>
                    </div>
                  </div>
                  <table class="w-full divide-y divide-gray-300 mt-4">
                    <thead class="divide-x divide-gray-20">
                    <tr class="divide-x divide-gray-200">
                      <th scope="col"
                          class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">Team
                      </th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          v-for="(key) in weekstartData.results.columns" :key="key">
                        {{ key }}
                      </th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        YTD
                      </th>
                    </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                    <tr v-for="team in weekstartData.results.data" :key="team.id" class="divide-x divide-gray-200">
                      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                        {{ team.name }}
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500" v-for="(key) in weekstartData.results.columns" :key="key">
                        <RingSpan :fraction="team.billableHours[key] / team.capacity[key]" v-if="!resultsInHours"/>
                        <RingSpan :fraction="team.billableHours[key]" :colored="false" :isPercentage="false" v-if="resultsInHours"/>
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <RingSpan :fraction="team.billableHoursTotal / team.capacityTotal" v-if="!resultsInHours"/>
                        <RingSpan :fraction="team.billableHoursTotal" :colored="false" :isPercentage="false" v-if="resultsInHours"/>
                      </td>
                    </tr>
                    <tr class="divide-x divide-gray-200 border-r-gray-100">
                      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                        <span class="font-weight-bold">Acato</span>
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500" v-for="(key) in weekstartData.results.columns" :key="key">
                        <RingSpan :fraction="weekstartData.results.dataAll.billableHours[key] / weekstartData.results.dataAll.capacity[key]" v-if="!resultsInHours"/>
                        <RingSpan :fraction="weekstartData.results.dataAll.billableHours[key]" :colored="false" :isPercentage="false" v-if="resultsInHours"/>
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <RingSpan :fraction="weekstartData.results.dataAll.billableHoursTotal / weekstartData.results.dataAll.capacityTotal" v-if="!resultsInHours"/>
                        <RingSpan :fraction="weekstartData.results.dataAll.billableHoursTotal" :colored="false" :isPercentage="false" v-if="resultsInHours"/>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MainFooter/>
    <ProjectModal/>
  </div>
</template>

<script setup>
import {onActivated, onMounted, ref} from 'vue'
import {useRouter} from "vue-router";
import ProjectModal from "@/components/Modals/ProjectModal";
import {useAuthStore} from "@/stores/auth-module";
import MainFooter from "@/components/MainFooter";
import MainNav from "@/components/MainNav";
import ApiService from "@/services/ApiService"
import {Switch} from '@headlessui/vue'
import RingSpan from "@/components/RingSpan.vue";

const loadingImage = require('@/assets/loading-animation.svg');

const router = useRouter();
const weekstartData = ref();
const loading = ref(true);
const inclND = ref(false);
const inHours = ref(false);
const resultsInHours = ref(false);

onActivated(() => {
  const authStore = useAuthStore()
  if (!authStore.loggedIn) {
    router.push('/');
  }
})

onMounted(() => {
  ApiService.get('data/weekstart').then((response) => {
    weekstartData.value = response.data
    loading.value = false;
  })
})

</script>

<style scoped>
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

.load-wrapper {
  transform: translatey(0px);
  -webkit-animation: floating 1.5s linear infinite;
  animation: floating 4s ease-in-out infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes floating {
  0% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0 rgba(0, 0, 0, 0.2);
    transform: translatey(-10px);
  }
  100% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}
</style>
