<template>
  <div class="bg-zinc-50">
    <MainNav/>
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="pt-6 lg:grid">
        <div class="lg:col-span-2 lg:mt-0 xl:col-span-2">
          <div class="mt-5 pb-20 min-h-full">
            <div class="grid grid-cols-12 gap-3 wrapper">
              <div class="col-span-12 bg-white shadow">
                <div class="mt-10 mb-10 flex justify-center" v-if="loading">
                  <img :src="loadingImage">
                </div>
                <div class="p-3" v-if="!loading">
                  <div class="border-b border-gray-200 pb-1 pt-4">
                    <h1 class="text-4xl mb-5 font-bold tracking-tight text-gray-900">Projectcheck</h1>
                  </div>
                  <div class="col-start-1 col-end-3">
                    <div class="flex rounded-md shadow-sm mb-2">
                      <div class="relative w-full">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor"
                               viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                  clip-rule="evenodd"></path>
                          </svg>
                        </div>
                        <input type="text" v-model="searchValue" id="simple-search"
                               class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                               placeholder="Zoeken" required>
                      </div>
                    </div>
                  </div>
                  <EasyDataTable
                      :headers="headers"
                      :items="projectcheckData.projects"
                      :search-field="searchField"
                      :search-value="searchValue"
                      :rows-per-page="20"
                      :alternating="true"
                      sort-by="hourly_rate"
                      sort-type="asc"
                      buttons-pagination
                      :loading="loading"
                      class="shadow"
                  >
                    <template #header-gripp_id>
                      <div class="customize-header">
                        <img class="header-icon" :src="grippLogo">
                      </div>
                    </template>
                    <template #item-gripp_id="item">
                      <a :href="`https://acato.gripp.com/public/index.php?u=96054#`+jsonGrippLink(item.gripp_id)"
                         class="text-blue-700" target="_blank">#{{ item.gripp_id }}</a>
                    </template>
                    <template #item-percentageOfHoursUsed="item">
                      {{formatNumberToPercentage(item.percentageOfHoursUsed)}}
                    </template>
                  </EasyDataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MainFooter/>
    <ProjectModal/>
  </div>
</template>

<script setup>
import {onActivated, onMounted, ref} from 'vue'
import {useRouter} from "vue-router";
import ProjectModal from "@/components/Modals/ProjectModal";
import {useAuthStore} from "@/stores/auth-module";
import MainFooter from "@/components/MainFooter";
import MainNav from "@/components/MainNav";
import ApiService from "@/services/ApiService"

const loadingImage = require('@/assets/loading-animation.svg');

const router = useRouter();
const projectcheckData = ref();
const loading = ref(true);
const searchField = ref(['company', 'name', 'manager']);
const searchValue = ref('');
const grippLogo = require('@/assets/gripp.webp');

onActivated(() => {
  const authStore = useAuthStore()
  if (!authStore.loggedIn) {
    router.push('/');
  }
})

onMounted(() => {
  ApiService.get('data/projectcheck').then((response) => {
    projectcheckData.value = response.data
    loading.value = false;
  })
})

const headers = [
  {text: "ID", value: "gripp_id"},
  {text: "Klant", value: "company", sortable: true},
  {text: "Opdracht", value: "name", sortable: true},
  {text: "Projectleider", value: "manager", sortable: true},
  {text: "Geschreven uren", value: "billableWrittenHours", sortable: true},
  {text: "Beschikbare uren", value: "availableBillableHours", sortable: true},
  {text: "Percentage gebruikt", value: "percentageOfHoursUsed", sortable: true},
];

const jsonGrippLink = function (id) {
  return btoa('{"action":"viewSubpage","actionparams":{"sn_":{"n9098n":{"recordid":"' + id + '","o_":"view"}},"re_":"n9102n"}}');
}

// const getClassColor = function (number) {
//   if (number < 0.08) {
//     return '';
//   }
//   if (number < 0.2) {
//     return 'bg-red-300';
//   }
//   if (number < 0.3) {
//     return 'bg-red-200';
//   }
//   if (number < 0.4) {
//     return 'bg-orange-200';
//   }
//   if (number < 0.5) {
//     return 'bg-orange-100';
//   }
//   if (number < 0.6) {
//     return 'bg-yellow-100';
//   }
//   if (number < 0.7) {
//     return 'bg-green-100';
//   }
//
//   return 'bg-green-200';
// }

const formatNumberToPercentage = function (number) {
  if (isNaN(number)) {
    return '0%'
  }
  return (number * 100).toFixed(0) + '%'
}

</script>

<style scoped>
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

.load-wrapper {
  transform: translatey(0px);
  -webkit-animation: floating 1.5s linear infinite;
  animation: floating 4s ease-in-out infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes floating {
  0% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0 rgba(0, 0, 0, 0.2);
    transform: translatey(-10px);
  }
  100% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}


</style>
