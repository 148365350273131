<template>
  <div class="bg-zinc-50">
    <MainNav/>
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="pt-6 lg:grid">
        <div class="lg:col-span-2 lg:mt-0 xl:col-span-2">
          <nav class="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
            <a v-for="(tab, key) in tabs" :key="key" @click="switchTab(key)"
               :class="[
                   tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                   key === 0 ? 'rounded-l-lg' : '', key === tabs.length - 1 ? 'rounded-r-lg' : '', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10',
                   tabStore.loading ? 'cursor-wait' : 'cursor-pointer']"
               :aria-current="key === router.currentRoute._value.query.tab ? 'page' : undefined">
              <span>{{ tab.name }}</span>
              <span aria-hidden="true"
                    :class="[key === router.currentRoute._value.query.tab ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5']"/>
            </a>
          </nav>
        </div>
        <div class="lg:col-span-2 lg:mt-0 xl:col-span-2">
          <div class="mt-5 pb-20 min-h-full">
            <div class="grid grid-cols-12 gap-3 wrapper">
              <aside class="col-span-3 bg-white shadow sidebar">
                <SideFilter/>
              </aside>
              <main class="col-span-9 bg-white shadow main">
                <div class="p-5">
                  <WrittenHours v-if="router.currentRoute._value.query.tab === 'writtenHours' && !tabStore.loading"/>
                  <QuotationTab v-if="router.currentRoute._value.query.tab === 'quotationTab' && !tabStore.loading"/>
                  <div class="mt-10 mb-10 flex justify-center" v-if="tabStore.loading">
                    <img :src="loadingImage">
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MainFooter/>
    <ProjectModal/>
  </div>
</template>

<script setup>
import {onActivated, onMounted, watch} from 'vue'
import SideFilter from "@/components/SideFilter"
import WrittenHours from "@/components/DataBoards/WrittenHours";
import {useRouter} from "vue-router";
import ProjectModal from "@/components/Modals/ProjectModal";
import {useTabStore} from "@/stores/tab-module";
import {useAuthStore} from "@/stores/auth-module";
import QuotationTab from "@/components/DataBoards/QuotationTab";
import MainFooter from "@/components/MainFooter";
import MainNav from "@/components/MainNav";

const loadingImage = require('@/assets/loading-animation.svg');
const router = useRouter();
const tabs = {
  writtenHours: {
    name: 'Uren & planning'
  },
  quotationTab: {
    name: 'Offertes'
  }

}
const tabKeys = Object.keys(tabs)
const tabStore = useTabStore()

onActivated(() => {
  const authStore = useAuthStore()
  if (!authStore.loggedIn) {
    router.push('/');
  }
})

onMounted(() => {
  if (router.currentRoute._value.query.tab === undefined || !tabKeys.includes(router.currentRoute._value.query.tab)) {
    router.replace({query: {tab: 'writtenHours'}})
  }
  tabStore.loadTabData(router.currentRoute._value.query.tab)
})

watch(router.currentRoute, currentRoute => {
  if (!tabStore.loading) {
    tabStore.loadTabData(currentRoute.query.tab)
  }
})

const switchTab = function (key) {
  if (!tabStore.loading) {
    router.replace({query: {tab: key}})
  }
}

</script>

<style scoped>
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

.load-wrapper {
  transform: translatey(0px);
  -webkit-animation: floating 1.5s linear infinite;
  animation: floating 4s ease-in-out infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes floating {
  0% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0 rgba(0, 0, 0, 0.2);
    transform: translatey(-10px);
  }
  100% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.main {
  width: 75%;
}

.sidebar {
  width: 25%;
  height: 100vh;
}

.sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

</style>
