<template>
  <Disclosure as="nav" class="bg-white shadow" v-slot="{ open }">
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="relative flex h-16 justify-between">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
              class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true"/>
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true"/>
          </DisclosureButton>
        </div>
        <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex flex-shrink-0 items-center">
            <img class="block h-8 w-auto lg:hidden"
                 :src="acatoLogo" alt="Acato"/>
            <img class="hidden h-12 w-auto lg:block"
                 :src="acatoLogo" alt="Acato"/>
          </div>
          <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
            <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
            <a href="/dashboard?tab=writtenHours"
               class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900">Dashboard</a>
            <a href="/weekstart"
               class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900">Weekstart</a>
            <div class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900">
              <Popover class="relative">
                <PopoverButton class="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                  <span>Project Managers</span>
                  <ChevronDownIcon class="h-5 w-5" aria-hidden="true"/>
                </PopoverButton>

                <transition enter-active-class="transition ease-out duration-200"
                            enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0"
                            leave-active-class="transition ease-in duration-150"
                            leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                  <PopoverPanel class="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                    <div
                        class="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                      <div class="p-4">
                        <div v-for="item in projectManagers" :key="item.name"
                             class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                          <div
                              class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                            <component :is="item.icon" class="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                                       aria-hidden="true"/>
                          </div>
                          <div>
                            <a :href="item.href" class="font-semibold text-gray-900">
                              {{ item.name }}
                              <span class="absolute inset-0"/>
                            </a>
                            <p class="mt-1 text-gray-600">{{ item.description }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </PopoverPanel>
                </transition>
              </Popover>
            </div>
<!--            <div class="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900">-->
<!--              <Popover class="relative">-->
<!--                <PopoverButton class="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">-->
<!--                  <span>Teamleads</span>-->
<!--                  <ChevronDownIcon class="h-5 w-5" aria-hidden="true"/>-->
<!--                </PopoverButton>-->

<!--                <transition enter-active-class="transition ease-out duration-200"-->
<!--                            enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0"-->
<!--                            leave-active-class="transition ease-in duration-150"-->
<!--                            leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">-->
<!--                  <PopoverPanel class="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">-->
<!--                    <div-->
<!--                        class="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">-->
<!--                      <div class="p-4">-->
<!--                        <div v-for="item in teamLeads" :key="item.name"-->
<!--                             class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">-->
<!--                          <div-->
<!--                              class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">-->
<!--                            <component :is="item.icon" class="h-6 w-6 text-gray-600 group-hover:text-indigo-600"-->
<!--                                       aria-hidden="true"/>-->
<!--                          </div>-->
<!--                          <div>-->
<!--                            <a :href="item.href" class="font-semibold text-gray-900">-->
<!--                              {{ item.name }}-->
<!--                              <span class="absolute inset-0"/>-->
<!--                            </a>-->
<!--                            <p class="mt-1 text-gray-600">{{ item.description }}</p>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </PopoverPanel>-->
<!--                </transition>-->
<!--              </Popover>-->
<!--            </div>-->
          </div>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <!--            <button type="button"-->
          <!--                    class="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">-->
          <!--              <span class="sr-only">View notifications</span>-->
          <!--              <BellIcon class="h-6 w-6" aria-hidden="true"/>-->
          <!--            </button>-->

          <!-- Profile dropdown -->
          <Menu as="div" class="relative ml-3">
            <div>
              <MenuButton
                  class="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">Open user menu</span>
                <img class="h-8 w-8 rounded-full"
                     :src="person"
                     alt=""/>
              </MenuButton>
            </div>
            <transition enter-active-class="transition ease-out duration-200"
                        enter-from-class="transform opacity-0 scale-95"
                        enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-from-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95">
              <MenuItems
                  class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem v-slot="{ active }">
                  <a :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                     @click="authStore.logout()">Uitloggen</a>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 pt-2 pb-4">
        <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" -->
        <DisclosureButton as="a" href="/dashboard?tab=writtenHours"
                          class="block border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700">
          Dashboard
        </DisclosureButton>
        <DisclosureButton as="a" href="/weekstart"
                          class="block border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700">
          Weekstart
        </DisclosureButton>
        <DisclosureButton as="a" href="/billable-hours"
                          class="block border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700">
          Facturatiecheck
        </DisclosureButton>
        <DisclosureButton as="a" href="/projectcheck"
                          class="block border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700">
          Projectcheck
        </DisclosureButton>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import {Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'
import {Bars3Icon, XMarkIcon} from '@heroicons/vue/24/outline'
import {useAuthStore} from "@/stores/auth-module";
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue'
import {ChevronDownIcon} from '@heroicons/vue/20/solid'
import {
    ChartPieIcon,
    CurrencyEuroIcon
} from '@heroicons/vue/24/outline'

const projectManagers = [
  {
    name: 'Projectcheck',
    description: "Jouw projecten met de belangrijkste KPI's",
    href: '/projectcheck',
    icon: ChartPieIcon
  },
  {
    name: 'Facturatiecheck',
    description: "Bekijk wat jij nog kan factureren",
    href: '/billable-hours',
    icon: CurrencyEuroIcon
  },
  {
    name: 'Opdrachten uurtarief',
    description: "Bekijk hoe het met de A en P uurtarieven staat",
    href: '/hourly-rate-v',
    icon: CurrencyEuroIcon
  },
  {
    name: 'Planning projectleiders',
    description: "Wie heeft het druk?",
    href: '/pm-planning',
    icon: ChartPieIcon
  }
]

// const teamLeads = [
//   {
//     name: 'Urencheck',
//     description: "Zijn alle uren geschreven en gefiatteerd?",
//     href: '/teamleads/hour-check',
//     icon: ClockIcon
//   }
// ]

const person = require('@/assets/person.jpg');
const acatoLogo = require('@/assets/acato-logo.png')

const authStore = useAuthStore()

</script>
