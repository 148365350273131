<template>
  <apexchart width="100%" :options="props.options" :series="props.series" :height="props.height"/>
</template>

<script setup>
import {defineProps} from "vue";

const props = defineProps({
  options: {
    default: []
  },
  series: {
    default: []
  },
  height: {
    height: '350px'
  },
  title: {
    default: 'Titel'
  }

})

</script>

<style>
.apexcharts-toolbar {
  z-index: 10 !important;
}
</style>
