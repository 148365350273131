<template>
  <div class="bg-zinc-50">
    <MainNav/>
    <div class="mx-auto px-2 sm:px-6 lg:px-8">
      <div class="pt-6 lg:grid">
        <div class="lg:col-span-2 lg:mt-0 xl:col-span-2 bg-white shadow">
          <div class="p-5">
            <div class="border-b border-gray-200 pb-1 pt-4">
              <h1 class="text-4xl mb-5 font-bold tracking-tight text-gray-900">Opdrachten</h1>
              <p class="text-gray-900 text-sm mb-1">
                Hieronder een overzicht van alle (actieve) opdrachten.
              </p>
              <p class="text-gray-900 text-sm mb-3">
                <span><strong>Uurtarief A:</strong> Gefactureerde omzet / alle geschreven uren </span><br>
                <span><strong>Uurtarief P:</strong> Gefactureerde + concept omzet / alle geschreven + toekomstig geplande uren</span>
              </p>
              <div class="grid-cols-8 mt-2">
                <div class="mt-10 mb-10 flex justify-center" v-if="hourlyRateVStore.loading">
                  <img :src="loadingImage">
                </div>
                <div v-if="!hourlyRateVStore.loading">
                  <ag-grid-vue
                      :rowData="hourlyRateVStore.reports.tableData"
                      :columnDefs="colDefs"
                      style="height: 1000px"
                      :pagination="true"
                      :paginationPageSize="20"
                      :frameworkComponents="frameworkComponents"
                      :gridOptions="gridOptions"
                      @grid-ready="onGridReady"
                  >
                  </ag-grid-vue>
                </div>
                <GrippTimeline/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MainFooter/>
    <ProjectModal/>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import ProjectModal from '@/components/Modals/ProjectModal'
import {useHourlyRateVStore} from '@/stores/hourly-rate-module'
import MainFooter from '@/components/MainFooter'
import MainNav from '@/components/MainNav'

import GrippTimeline from '@/components/Modals/GrippTimeline'
import {AgGridVue} from "ag-grid-vue3";
import {AllCommunityModule, ModuleRegistry} from 'ag-grid-community';

const hourlyRateVStore = useHourlyRateVStore()
const loadingImage = require('@/assets/loading-animation.svg');

ModuleRegistry.registerModules([AllCommunityModule]);

const colDefs = ref([
  {
    headerName: "Bedrijf",
    field: "company"
  },
  {
    headerName: "Opdracht",
    field: "project",
  },
  {
    headerName: "Business Developer",
    field: "business_developer",
    filter: 'agTextColumnFilter',
    filterParams: {
      maxNumConditions: 1
    }
  },
  {
    headerName: "Project Manager",
    field: "project_manager",
    filter: 'agTextColumnFilter',
    filterParams: {
      maxNumConditions: 1
    }
  },
  {
    headerName: "Geschreven",
    field: "written_hours",
    filter: 'agNumberColumnFilter',
    valueFormatter: params => (params.value ?? 0) + ' uur',
    filterParams: {
      maxNumConditions: 2
    }
  },
  {
    headerName: "Gepland",
    field: "planned_hours",
    valueFormatter: params => (params.value ?? 0) + ' uur',
    filter: 'agNumberColumnFilter',
    filterParams: {
      maxNumConditions: 2
    }
  },
  {
    headerName: "Uurtarief Act.",
    field: "hourly_rate_a",
    filter: 'agNumberColumnFilter',
    valueFormatter: params => '€ ' + (Math.round(params.value) ?? 0) + ',-',
    filterParams: {
      maxNumConditions: 2
    }
  },
  {
    headerName: "Uurtarief Progn.",
    field: "hourly_rate_p",
    valueFormatter: params => '€ ' + Math.round(params.value) ?? 0 + ',-',
    filter: 'agNumberColumnFilter',
    filterParams: {
      maxNumConditions: 2
    }
  },
]);

onMounted(() => {
  hourlyRateVStore.loadData()
})

const onGridReady = (params) => {
  params.api.sizeColumnsToFit();

  params.api.setFilterModel({
    hourly_rate_a: {
      type: 'greaterThan',
      filter: 0
    },
    hourly_rate_p: {
      type: 'greaterThan',
      filter: 0
    },
    planned_hours: {
      type: 'greaterThan',
      filter: 50
    },
    written_hours: {
      type: 'greaterThan',
      filter: 50
    },
  });

  // params.api.setSortModel([
  //   { colId: 'hourly_rate_p', sort: 'asc' }
  // ]);
}

const gridOptions = {
  rowClassRules: {
    'error-row-1': (params) => params.data.hourly_rate_p < 50,
    'error-row-2': (params) => params.data.hourly_rate_p > 49 && params.data.hourly_rate_p < 65,
    'error-row-3': (params) => params.data.hourly_rate_p > 64 && params.data.hourly_rate_p < 80,
    'error-row-4': (params) => params.data.hourly_rate_p < 100 && params.data.hourly_rate_p > 80,
  }
};

</script>

<style>
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes floating {
  0% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0 rgba(0, 0, 0, 0.2);
    transform: translatey(-10px);
  }
  100% {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

.error-row-1 {
  background-color: rgba(255, 0, 0, 0.1); /* Pure red */
}

.error-row-2 {
  background-color: rgba(255, 64, 0, 0.1); /* A slightly lighter red/orange */
}

.error-row-3 {
  background-color: rgba(255, 128, 0, 0.1); /* Midway between red and orange */
}

.error-row-4 {
  background-color: rgba(255, 191, 0, 0.1); /* A light orange */
}

</style>
