<template>
  <span class="inline-flex mr-2 items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
        :class="props.colored ? getClassColor(props.fraction) : 'ring-slate-200'">
    <span v-if="props.isPercentage && !showRawValue">{{ formatNumberToPercentage(props.fraction) }}</span>
    <span v-if="!props.isPercentage && !showRawValue">{{ formatHours(props.fraction) }}</span>
    <span v-if="showRawValue">{{ props.fraction }}</span>
  </span>
</template>

<script setup>
import {defineProps} from "vue";

const props = defineProps({
  fraction: {
    default: 0
  },
  colored: {
    default: true
  },
  isPercentage: {
    default: true
  },
  showRawValue: {
    default: false
  }
})

const getClassColor = function (number) {
  if (number < 0.1 && !props.showRawValue) {
    return 'bg-red-300 ring-red-500';
  }
  if (number < 0.2 && !props.showRawValue) {
    return 'bg-red-200 ring-red-400';
  }
  if (number < 0.3 && !props.showRawValue) {
    return 'bg-red-100 ring-red-300';
  }
  if (number < 0.4 && !props.showRawValue) {
    return 'bg-orange-100 ring-orange-300';
  }
  if (number < 0.5 && !props.showRawValue) {
    return 'bg-orange-200 ring-orange-400';
  }
  if (number < 0.6 && !props.showRawValue) {
    return 'bg-yellow-100 ring-yellow-300';
  }
  if (number < 0.7 && !props.showRawValue) {
    return 'bg-green-100 ring-green-200';
  }

  if (number < 40 && props.showRawValue) {
    return 'bg-red-300 ring-red-500';
  }
  if (number < 50 && props.showRawValue) {
    return 'bg-red-200 ring-red-400';
  }
  if (number < 60 && props.showRawValue) {
    return 'bg-red-100 ring-red-300';
  }
  if (number < 75 && props.showRawValue) {
    return 'bg-orange-100 ring-orange-300';
  }
  if (number < 100 && props.showRawValue) {
    return 'bg-yellow-100 ring-yellow-200';
  }
  if (number < 125 && props.showRawValue) {
    return 'bg-green-100 ring-green-200';
  }
  if (number < 150 && props.showRawValue) {
    return 'bg-green-100 ring-green-400';
  }

  return 'bg-green-200 ring-green-400';
}

const formatNumberToPercentage = function (number) {
  if (isNaN(number)) {
    return '0%'
  }
  return (number * 100).toFixed(0) + '%'
}

const formatHours = function (number) {
  if (isNaN(number)) {
    return '0%'
  }
  return number.toFixed(0);
}

</script>
